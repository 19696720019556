import React, { Fragment } from 'react';
import styled from 'styled-components';

import Dropdown from 'commons/components/Dropdown';
import Typo from 'commons/components/Typo';

import destinations from 'utils/dropdown/experience-destinations.json';
import { optionsActivity } from 'utils/dropdown/activity';

const optionDestinations = Object.keys(destinations).map(key => {
  const optionItem = destinations[key].map(option => ({ value: JSON.stringify(option), label: option.label }));
  return {
    group: key,
    items: optionItem
  };
});

const TitleDropdrown = styled(Typo.HeaderThree)`
  color: ${props => props.theme.darkGray};
`;

const DropdrownContainer = styled.div`
  width: 25%;
  padding: 8px 19px 0px;
  border: 1px solid ${props => props.theme.blueGray};
  margin-right: 1%;
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      width: 49.5%;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-top: 2%;
    }
  `}
`;

const Destination = styled(DropdrownContainer)`
  ${props => !props.nonResponsive && `
    @media (max-width: 575px) {
      margin-top: 0;
    }
  `}
`;

const Activity = styled(DropdrownContainer)`
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      margin-right: 0;
    }
  `}
`;


const DropdownLocation = (props) => {
  const { destination, activity, nonResponsive } = props;
  return (
    <Fragment>
      <Destination nonResponsive={nonResponsive}>
        <TitleDropdrown>Destination</TitleDropdrown>
        <Dropdown
          options={optionDestinations}
          selected={destination}
          handleOnChange={(e) => props.onChange(e.target.value, 'destination')}
        />
      </Destination>
      <Activity nonResponsive={nonResponsive}>
        <TitleDropdrown>Activity</TitleDropdrown>
        <Dropdown
          options={optionsActivity}
          selected={activity}
          placeholder="Popular Activities"
          handleOnChange={(e) => props.onChange(e.target.value, 'activity')}
        />
      </Activity>
    </Fragment>
  );
};

export default DropdownLocation;
