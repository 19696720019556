export default {
  darkBlue: '#0f4b85',
  darkBlue2: '#425e6c',
  darkGray: '#939393',
  blueGray: '#f3f5f6',
  white: '#fff',
  main: '#1cb6e6',
  blue: '#1cb6e6',
  blueLight: '#24cbed',
  black: '#283941',
  orange: '#e86a1a',
  orangeLight: '#ee8a21',
  orangeLighter: '#f3ac2a',
  yellow: '#f0bb42',
  green: '#0b6416',
  greenLighter: '#16c464',
  coral: '#ff4141',
  darkBlueLighter: '#4f7183',
  blueGrayLighter: '#f0f5f7',
  blueGrayDarker: '#abc5d0',
  blueGrayDark: '#baced8',
  darkGray1: '#979797',
  darkGray2: '#9b9b9b',
  disabledLight: '#eaf2f5',
  disableDark: '#39525f',
  lightGray: '#d0d0d0',
  lightGray1: '#d8d8d8',
  lightGray2: '#f2f2f2',
};
