import React from 'react';
import styled from 'styled-components';

import { Paragraph } from '../base/Typo';

const StarContainer = styled.span`
  position: relative;
  font-size: 12px;
  display: inline-block;
  line-height: 1.17;
`;

const OrangeStar = styled.span`
  color: ${props => props.theme.yellow};
  width: ${props => props.percentage || 0}%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
`;

const GrayStar = styled.span`
  color: ${props => props.theme.lightGray};
`;

const ReviewsCount = styled(Paragraph)`
  font-size: 10px;
  display: inline-block;
  margin-left: 1px;
`;

// decimal digit is 1, max score is 5.0
const getPercentage = (score) => {
  const intScore = parseInt(score, 10);
  const decScore = score - intScore;
  let percentage = intScore * 20;

  if (decScore >= 0.1 && decScore <= 0.3) percentage += 8;
  if (decScore >= 0.4 && decScore <= 0.6) percentage += 10;
  if (decScore >= 0.7 && decScore <= 0.9) percentage += 12;
  if (decScore > 0.9) percentage += 20;

  return percentage;
};

const ReviewStar = props => {
  // eslint-disable-next-line object-curly-newline
  const { className, score = 0, count = 0 } = props;
  const percentage = getPercentage(score);

  return (
    <div className={className}>
      <StarContainer>
        <OrangeStar percentage={percentage}>
          <span>★★★★★</span>
        </OrangeStar>
        <GrayStar>
          <span>★★★★★</span>
        </GrayStar>
      </StarContainer>
      <ReviewsCount>{`(${count.toString()})`}</ReviewsCount>
    </div>
  );
};

export default ReviewStar;
