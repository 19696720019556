import React from 'react';
import styled from 'styled-components';
import Typo from 'commons/components/Typo';

const Container = styled.div`
  width: 25%;
  padding: 8px 19px 0px;
  border: 1px solid ${props => props.theme.blueGray};
  margin-right: 0;
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      width: 49.5%;
      margin-top: 1%;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-top: 2%;
    }
  `}
`;

const Title = styled(Typo.HeaderThree)`
  color: ${props => props.theme.darkGray};
`;

const CounterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Conter = styled.div`
  display: flex;
  align-items: center;
`;

const IconConter = styled.i`
  font-size: 12px;
  cursor: pointer;
  color: ${props => props.theme.darkBlue};
  ${props => (props.reverse ? 'margin-left: 16px;' : 'margin-right: 16px;')}
`;

const TravelerCounter = (props) => {
  const { adult, nonResponsive } = props;
  return (
    <Container nonResponsive={nonResponsive}>
      <Title>
        Traveler
        {adult > 1 && '(s)'}
      </Title>
      <CounterContainer>
        <Typo.HeaderOne>Adult</Typo.HeaderOne>
        <Conter>
          <IconConter
            onClick={() => props.onChangeConter('minus')}
            className="fas fa-minus"
          />
          <Typo.HeaderOne>{adult}</Typo.HeaderOne>
          <IconConter
            onClick={() => props.onChangeConter('plus')}
            reverse
            className="fas fa-plus"
          />
        </Conter>
      </CounterContainer>
    </Container>
  );
};

export default TravelerCounter;
