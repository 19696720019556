import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';

import Typo from './Typo';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

const Selected = styled(Typo.HeaderOne)`
  margin-right: 10px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -8px;
`;

const Select = styled.select`
  opacity: 0;
  z-index: 100;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 0;
  box-sizing: border-box;
  padding-left: 20px;
  user-select: none;
  font-size: 20px;
  font-weight: 200;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  font-family: helvathaica;
  background: ${props => props.theme.white};
  color: ${props => props.theme.darkBlue};
`;

const IconContainer = styled.div`
  box-sizing: border-box;
  margin-top: -8px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.darkBlue};
`;

const Icon = styled.i`
  font-size: 18px;
`;

const enhance = compose(
  withProps(
    props => {
      const { options, selected } = props;
      const allOptions = options.reduce((prev, curr) => (
        curr.items ? [...prev, ...curr.items] : [...prev, curr]
      ), []);
      const selectedObject = allOptions.find(option => option.value === selected);
      const selectedLabel = selectedObject && selectedObject.label;
      return { selectedLabel };
    }
  )
);

const Dropdown = (props) => {
  const {
    fontSize,
    options,
    handleOnChange,
    selected,
    placeholder,
    selectedLabel,
    currencyLabel
  } = props;
  return (
    <Container>
      <Selected>
        { currencyLabel || selectedLabel || placeholder || 'Please select...'}
      </Selected>
      <Select value={selected} onChange={handleOnChange} fontSize={fontSize}>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => {
          if (option.group) {
            return (
              <optgroup label={option.group} key={option.group}>
                {option.items.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </optgroup>
            );
          }
          return (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Select>
      <IconContainer>
        <Icon className="fas fa-angle-down" />
      </IconContainer>
    </Container>
  );
};

Dropdown.propTypes = {
  selected: PropTypes.string,
  options: PropTypes.array,
  handleOnChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectedLabel: PropTypes.string,
  currencyLabel: PropTypes.string,
  fontSize: PropTypes.number
};

export default enhance(Dropdown);
