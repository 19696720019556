import styled from 'styled-components';

const TakeMeTourIcon = styled.i.attrs(props => ({
  className: `icon icon-${props.name}${props.className || ''}`,
}))`
  font-family: "TakeMeTourIcon", Tahoma, sans-serif !important;
  font-size: ${props => props.size};
  width: ${props => props.size};
  height: ${props => props.size};
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default TakeMeTourIcon;
