import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  compose,
  withState,
  withProps,
  branch
} from 'recompose';

import {
  CELL,
  handleSingle,
  handleMonthYear,
  fillDefault,
  mapFunction,
  mapColor,
  fillFrontBack,
  highlightSelected,
  fillRange
} from 'utils/calendar';
import CalendarRenderer from './CalendarRenderer';

moment.tz.setDefault('Asia/Bangkok');

const enhance = compose(
  withState('value', 'setValue', props => (props.defaultDate ? [{ date: props.defaultDate }] : [])),
  withState('monthYear', 'onChangeMonthYear', props => (props.defaultDate ? moment(props.defaultDate).format('MMMM YYYY') : moment().format('MMMM YYYY'))),
  withProps(
    props => ({
      onChange: (date) => {
        props.setValue(date);
        if (props.onChange) props.onChange(date);
      }
    })
  ),
  handleSingle,
  handleMonthYear,
  fillDefault(),
  mapFunction(
    mapColor(),
    // fillRange([null, moment().subtract(1, 'day').startOf('day')], { ...CELL.GRAY, disabled: true }),
  ),
  branch(props => !props.allowPastDate, mapFunction(fillRange([null, moment().subtract(1, 'day').startOf('day')], { ...CELL.GRAY, disabled: true }))),
  branch(props => props.disableToday, mapFunction(fillRange([null, moment().startOf('day')], { ...CELL.GRAY, disabled: true }))),
  fillFrontBack,
  highlightSelected()
);

CalendarRenderer.propTypes = {
  onChange: PropTypes.func
};

export default enhance(CalendarRenderer);
