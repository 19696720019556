import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Typo from 'commons/components/Typo';
import DropdrownLocation from '../components/DropdownLocation';
import TripDate from '../components/TripDate';
import TravelerCounter from '../components/TravelerCounter';


const Container = styled.div`
  padding: 10px 32px;
  margin: 0 auto;
  ${props => props.nonResponsive && `
    width: 1080px;
  `}
  ${props => !props.nonResponsive && `
    @media (max-width: 575px) {
      padding: 10px 16px;
    }
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  ${props => !props.nonResponsive && `
    @media (max-width: 768px) {
      display: block;
    }
  `}
`;

const Title = styled(Typo.HeaderOne)`
  font-size: 30px;
`;

const SubTitle = styled(Typo.HeaderThree)`
  margin-left: 14px;
  color: ${props => props.theme.darkGray};

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  margin-bottom: 8px;
  height: 70px;
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      display: flex;
      flex-wrap: wrap;
      height: initial;
    }
  `}
`;

const DetailContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      justify-content: flex-end;
    }
  `}
`;

const DetailContainer = styled.div`
  display: flex;
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      display: none;
    }
  `}
`;

const TextDetail = styled(Typo.HeaderThree)`
  color: ${props => props.theme.darkGray};
  ${props => !props.hideMargin && 'margin-right: 48px;'}
`;

const ButtonStyle = styled.button`
  font-family: helvathaica, Tahoma, sans-serif;
  user-select: none;
  padding: 12px 0px;
  border: none;
  color: ${props => props.theme.darkBlue};
  background-color: ${props => props.theme.white};
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:focus {
    outline: none;
  }
`;

const SearchButton = styled(ButtonStyle)`
  width: 255px;
  color: ${props => props.theme.white};
  background: ${props => props.theme.darkBlue};
  padding: 8px 0;
  ${props => !props.nonResponsive && `
    @media (max-width: 575px) {
      width: 100%;
    }
  `}
`;

const TextContact = styled(Typo.Paragraph)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${props => props.theme.darkGray};
  text-align: right;
  ${props => !props.nonResponsive && `
    @media (max-width: 575px) {
      display: none;
    }
  `}
`;

const IconCheck = styled.i`
  margin-right: 8px;
  color: ${props => props.theme.darkBlue};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
`;

const Logo = styled.img`
  width: 100px;
  height: 28px;
  margin-left: 8px;
  object-fit: contain;
`;

const Link = styled.a`
  color: ${props => props.theme.white};
  text-decoration: none;
`;


const SearchContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  ${props => !props.nonResponsive && `
    @media (max-width: 575px) {
      width: 100%;
      > * {
        flex: 1;
      }
    }
  `}
`;

class Bangkokair extends Component {
  state = {
    isCalendar: false,
    destination: '{"label":"Bangkok","url":"/to/Bangkok","query":{"city":"Bangkok"},"pathname":"/city"}',
    activity: '',
    date: moment(),
    adult: 1,
  };

  onChange = (value, type) => {
    this.setState({ isCalendar: false });
    if (type === 'destination') {
      this.setState({ destination: value });
    }
    if (type === 'activity') {
      this.setState({ activity: value });
    }
  }

  onClickToggleTripDate = () => {
    const { isCalendar } = this.state;
    this.setState({ isCalendar: !isCalendar });
  }

  onChangeTripDate = (tripDate) => {
    if (tripDate && tripDate.length > 0) {
      const { date } = tripDate[0];
      this.setState({ date, isCalendar: false });
    }
  }

  onChangeConter = (type) => {
    const { adult } = this.state;
    if (type === 'plus') {
      this.setState({ adult: adult + 1, isCalendar: false });
    }
    if (type === 'minus') {
      this.setState({ adult: adult <= 0 ? 0 : adult - 1, isCalendar: false });
    }
  }

  render() {
    const {
      isCalendar,
      destination,
      activity,
      date,
      adult
    } = this.state;
    const { nonResponsive } = this.props;
    const { url } = JSON.parse(destination);
    return (
      <Container nonResponsive={nonResponsive}>
        <TitleContainer nonResponsive={nonResponsive}>
          <Title>Select Your Activity</Title>
          <SubTitle>Book a private tour with local activities, food, and friends.</SubTitle>
        </TitleContainer>
        <Content nonResponsive={nonResponsive}>
          <DropdrownLocation
            destination={destination}
            activity={activity}
            onChange={this.onChange}
            nonResponsive={nonResponsive}
          />
          <TripDate
            date={date}
            isCalendar={isCalendar}
            onClickToggleTripDate={this.onClickToggleTripDate}
            onChangeTripDate={this.onChangeTripDate}
            nonResponsive={nonResponsive}
          />
          <TravelerCounter
            adult={adult}
            onChangeConter={this.onChangeConter}
            nonResponsive={nonResponsive}
          />
        </Content>
        <DetailContent nonResponsive={nonResponsive}>
          <DetailContainer nonResponsive={nonResponsive}>
            <TextDetail>
              <IconCheck className="fas fa-check"/>
              More Local
            </TextDetail>
            <TextDetail>
              <IconCheck className="fas fa-check"/>
              More Flexible
            </TextDetail>
            <TextDetail hideMargin>
              <IconCheck className="fas fa-check"/>
              Safer with Free Accident Insurance
            </TextDetail>
          </DetailContainer>
          <SearchContainer nonResponsive={nonResponsive}>
            <Link
              href={`https://www.takemetour.com${url}${activity !== '' ? `?activity=${activity}` : ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SearchButton nonResponsive={nonResponsive}>
                Search Activity
              </SearchButton>
            </Link>
            <LogoContainer>
              <TextContact nonResponsive={nonResponsive}>
                Powered by
              </TextContact>
              <Logo
                src={require('static/images/logo/logo.png')}
                srcSet={`${require('static/images/logo/logo@2x.png')} 2x`}
                alt="logo"
              />
            </LogoContainer>
          </SearchContainer>
        </DetailContent>
      </Container>
    );
  }
}

export default Bangkokair;
