export const optionsActivity = [
  { value: 'art-and-craft-workshops', label: 'Art & Craft Workshops' },
  { value: 'cooking', label: 'Cooking' },
  { value: 'fitness-and-wellness', label: 'Fitness & Wellness'},
  { value: 'floating-market', label: 'Floating Markets'},
  { value: 'food-and-drink', label: 'Food & Drink'},
  { value: 'fun-and-adventure', label: 'Fun & Adventure' },
  { value: 'history-and-culture', label: 'History & Culture' },
  { value: 'local-life', label: 'Local Life' },
  { value: 'local-ride', label: 'Local Ride' },
  { value: 'nature', label: 'Nature'},
  { value: 'night-activities', label: 'Night Activities'},
  { value: 'shopping', label: 'Shopping'},
  { value: 'volunteering-and-social-impact', label: 'Volunteering & Social Impact'},
];
