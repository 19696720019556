import React from 'react';
import styled from 'styled-components';
import SimpleCalendar from 'commons/components/Calendar/SimpleCalendar';
import Typo from 'commons/components/Typo';

const Container = styled.div`
  position: relative;
  width: 25%;
  padding: 8px 19px 0px;
  border: 1px solid ${props => props.theme.blueGray};
  margin-right: 1%;
  ${props => !props.nonResponsive && `
    @media (max-width: 990px) {
      margin-top: 1%;
      width: 49.5%;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-top: 2%;
    }
  `}
`;

const Title = styled(Typo.HeaderThree)`
  color: ${props => props.theme.darkGray};
`;


const CalendarContainer = styled.div`
`;

const Calendar = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
`;

const TextDate = styled(Typo.HeaderOne)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const IconDate = styled.i`
  font-size: 20px;
`;

const TripDate = (props) => {
  const {
    date, isCalendar, onClickToggleTripDate, nonResponsive
  } = props;
  return (
    <Container nonResponsive={nonResponsive}>
      <Title>Trip Date</Title>
      <CalendarContainer>
        <TextDate onClick={onClickToggleTripDate} >
          {date.format('D MMM YYYY')}
          <IconDate className="far fa-calendar-alt" />
        </TextDate>

        {isCalendar && (
          <Calendar>
            <SimpleCalendar
              defaultDate={date}
              onChange={e => props.onChangeTripDate(e)}
            />
          </Calendar>
        )}
      </CalendarContainer>
    </Container>
  );
};

export default TripDate;
