import config from 'config';

const defaultAssetPath = 'https://ddhiorx9qbcay.cloudfront.net';

export const s3PathCreator = staticAssetPath => prefix => path => `${staticAssetPath}/${prefix}${path}`;

export const path = (image, preset = 'thumbnail', noWaterMark = false) => {
  const presets = {
    thumbnail: 'w400-h300-cfill-q80',
    listingThumbnail: 'w580-h580-cfill-q80',
    profileAvatar: 'w80-h80-cfill-q100',
    profileAvatarNav: 'w40-h40-cfill-q100',
    profileAvatarNav2x: 'w80-h80-cfill-q100',
    profileAvatarSideNav: 'w80-h80-cfill-q100',
    profileAvatarSideNav2x: 'w160-h160-cfill-q100',
    profileCover: 'w2600-h800-cfill',
    avatarNavMini: 'w74-h74-cfill',
    avatarTripCard: 'w100-h100-cfill-q100',
    avatarTripCard2x: 'w200-h200-cfill-q100',
    avatarTripCardLowRes: 'w50-h50-cfill-q100',
    coverTripCard: 'w640-h576-cfill-q80',
    featureCover: 'w800-h370-cfill-q80',
    inspCol1: 'w660-h440-cfill-q80',
    inspCol2: 'w1320-h440-cfill-q80',
    gallery: 'w2560-h1440-q80',
    mobileGallery: 'w720-h1280-q80',
    modalImage: 'w500-h500-q80',
    profileNoti: 'w40-h40',
    tripCover: 'w1600-h1200-cfill',
    tripCoverLow: 'w80-h60-cfill-q10',
    'rectangle-310-233': 'w310-h233-cfill-q80',
    'rectangle-620-466': 'w620-h466-cfill-q80',
    reviewAvatar: 'w50-h50-cfill-q80',
    reviewAvatar2x: 'w100-h100-cfill-q80',
    cardThumbnail: 'w272-h208-cfill-q80',
    cardThumbnail2x: 'w544-h416-cfill-q80',
    cardThumbnailLowRes: 'w27-h20-cfill-q50',
    ticketThumbnail: 'w310-h233-cfill-q80',
    ticketThumbnail2x: 'w620-h466-cfill-q80',
    ticketThumbnailLowRes: 'w31-h23-cfill-q50',
    coverLxCard: 'w348-h348-cfill-q80',
    coverLxCard2x: 'w696-h696-cfill-q80',
    coverLxCardLowRes: 'w34-h34-cfill-q80',
  };
  if (!image || !image.match(/\.(jpg|jpeg|png|gif|bmp|JPG|JPEG|PNG|GIF|BMP)$/)) {
    return s3PathCreator(defaultAssetPath)('images')('/common/user.png');
  }
  const url = `${config.mediaPath}/${presets[preset]}/${encodeURI(image)}${noWaterMark ? '?noMark=true' : ''}`
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29');
  return url;
};
