import React from 'react';
import styled from 'styled-components';

import { convertPriceToExpectedPriceText, getPeriod } from 'utils/helpers';
import { path } from 'utils/media';

// import LazyImage from 'commons/components/LazyImage';
import Icon from 'commons/components/Icon';
import _ReviewStar from './ReviewStar';
import { Paragraph } from '../base/Typo';

const CURRENCY = 'USD';

const CardContainer = styled.div`
  position: relative;
  width: 246px;
  background-color: ${props => props.theme.white};
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${props => props.theme.blueGrayLighter};
  overflow: hidden;
`;

const CardImageContainer = styled.div`
  width: 100%;
  height: 188px;
  background: linear-gradient(118deg, rgb(246, 249, 251), rgb(226, 235, 239));
`;

const CardImage = styled.img`
  width: 100%;
`;

const DetailContainer = styled.div`
  padding: 19px 9px 12px 9px;
`;

const TitleAndDetail = styled.div`
  height: 66px;
`;

const Title = styled(Paragraph)`
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.36;
  margin-bottom: 2px;
`;

const Detail = styled(Paragraph)`
  opacity: 0.7;
  color: ${props => props.theme.darkBlue2};
  font-size: 12px;
`;

const DotSpanner = styled.span`
  margin: 0px 5px 0px 3px;
`;

const ReviewStarAndTimeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 37px;
`;

const ReviewStar = styled(_ReviewStar)`
  text-align: right;
  margin-bottom: 2px;
`;

const TimeAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1px 0 1px;
`;

const Time = styled.div`
  opacity: 0.7;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TimeIcon = styled(Icon).attrs({
  size: '18px',
})`
  color: ${props => props.theme.black};
`;

const TimeText = styled(Paragraph)`
  font-size: 10px;
`;

const Price = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 1.33;
`;

const PriceNumber = styled(Paragraph)`
  color: ${props => props.theme.greenLighter};
  font-weight: 500;
`;

const PriceText = styled(Paragraph)`
  font-size: 9px;
  opacity: 0.7;
`;

const BestSeller = styled(Paragraph)`
  position: absolute;
  z-index: 1;
  top: 178px;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 9px 4px 9px;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.orange};
  border-radius: 0 10px 10px 0;
`;

const IsTransportationMatched = transportation => ['Private Car', 'Private Van'].includes(transportation);

const TripCard = props => {
  const { className, trip, isBestSeller, exchangeRates } = props;
  const {
    name,
    transportation,
    duration,
    starting_time: startingTime,
    search_information: searchInformation,
    review_count: count,
    rating,
    card_image: cardImage,
    cover_image: coverImage,
    full_prices: fullPrices,
    max_travelers: maxTravelers
  } = trip;
  const discountedPrices = fullPrices.map(price => price.seasonedDiscountedPerPerson);
  const quantity = (maxTravelers > 2) ? 2 : 1;
  const discountedPrice = discountedPrices[quantity - 1];
  const price = convertPriceToExpectedPriceText(discountedPrice, CURRENCY, exchangeRates);

  return (
    <CardContainer className={className}>
      {isBestSeller && <BestSeller>Best Seller</BestSeller>}
      <CardImageContainer>
        <CardImage
          alt={name}
          src={path(cardImage || coverImage, 'cardThumbnail', true)}
          srcSet={path(cardImage || coverImage, 'cardThumbnail', true)}
        />
      </CardImageContainer>
      <DetailContainer>
        <TitleAndDetail>
          <Title>{name}</Title>
          <Detail>
            {IsTransportationMatched(transportation) && transportation}
            {IsTransportationMatched(transportation) && searchInformation && searchInformation.is_hotel_pickup && <DotSpanner>•</DotSpanner>}
            {searchInformation && searchInformation.is_hotel_pickup && 'Free hotel pickup'}
          </Detail>
        </TitleAndDetail>
        <ReviewStarAndTimeAndPrice>
          {(count > 0 && rating > 0) && (
            <ReviewStar count={count} score={rating}/>
          )}
          <TimeAndPrice>
            {(startingTime && duration) && (
              <Time>
                <TimeIcon name="clock-24" />
                <TimeText>{getPeriod(startingTime, duration)}</TimeText>
              </Time>
            )}
            <Price>
              <PriceNumber>{CURRENCY} {price}</PriceNumber>
              <PriceText>/ person</PriceText>
            </Price>
          </TimeAndPrice>
        </ReviewStarAndTimeAndPrice>
      </DetailContainer>
    </CardContainer>
  );
};

export default TripCard;
