import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Bangkokair from './features/bangkokair/pages';
import TripCardList from './features/blogger/pages/TripCardList';

const routes = [
  {
    path: '/',
    component: Bangkokair
  },
  {
    path: '/blogger',
    component: TripCardList
  },
  {
    path: '/bangkokair/non-responsive',
    component: () => <Bangkokair nonResponsive />
  },
  {
    path: '/bangkokair',
    component: Bangkokair
  },
];

const Routes = () => (
  <Switch>
    {routes.map((route, i) => (
      <Route
        key={i}
        path={route.path}
        exact
        component={route.component}
      />
    ))}
  </Switch>
);

export default Routes;
