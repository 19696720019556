import moment from 'moment';

export const commaizeInt = num => {
  console.log(((num && Math.ceil(num)) || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  return ((num && Math.ceil(num)) || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertPriceToExpectedPriceText = (price, cur, curs) => {
  if (cur in curs) {
    const converted = price * curs[cur];
    if (cur === 'JPY') return commaizeInt(converted);
    return converted.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return price;
};

const trailingZeroAfterInteger = (intNumber, length = 2) => (intNumber.toFixed(length - intNumber.toString().length).replace('.', ''));

export const getPeriod = (startingTime, duration) => {
  const momentStartingTime = moment.duration(startingTime);
  const momentEndingTime = moment.duration(startingTime).add(duration ? parseInt(duration.replace(' hours', ''), 10) : 0, 'hours');
  return `${momentStartingTime.hours()}:${trailingZeroAfterInteger(momentStartingTime.minutes(), 2)} - ${momentEndingTime.hours()}:${trailingZeroAfterInteger(momentEndingTime.minutes(), 2)}`;
};
