import {
  withProps,
  compose,
  withHandlers,
  renameProp
} from 'recompose';
import moment from 'moment-timezone';
import range from 'lodash/range';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

import colors from 'commons/components/theme';

moment.tz.setDefault('Asia/Bangkok');

export const CELL = {
  BLUE: {
    color: colors.darkBlue,
    textColor: colors.white
  },
  WHITE: {
    color: colors.white,
    textColor: colors.darkBlue,
  },
  GRAY: {
    color: colors.white,
    textColor: colors.disabledLight,
  },
  RED: {
    color: colors.red,
    textColor: colors.white,
  },
  YELLOW: {
    color: colors.yellow,
    textColor: colors.darkGray
  }
};

const defaultOptions = {
  fillDefault: CELL.WHITE,
  colorMap: {
    'n/a': CELL.RED,
    available: CELL.BLUE,
    ask: CELL.WHITE
  },
  highlightSelected: {
    selected: true,
    ...CELL.BLUE
  },
  highlightSelectedReplaceable: {
    selected: true,
    ...CELL.YELLOW
  }
};

export const mapFunction = (...args) => withProps(
  ({ dates }) => ({
    dates: dates.map(compose(...[...args].reverse()))
  })
);

export const mapColor = (colorMap = defaultOptions.colorMap) => date => {
  return {
    ...date,
    ...colorMap[date.status]
  };
};

export const fillRange = (dateRange, fillObject) => date => {
  const [firstBound, lastBound] = dateRange;
  const isSameOrBeforeLast = lastBound ? moment(date.date).isSameOrBefore(moment(lastBound), 'day') : false;
  const isSameOrAfterFirst = firstBound ? moment(date.date).isSameOrAfter(moment(firstBound), 'day') : false;
  if (
    (!firstBound && isSameOrBeforeLast)
    || (!lastBound && isSameOrAfterFirst)
    || (isSameOrAfterFirst && isSameOrBeforeLast)
  ) {
    return {
      ...date,
      ...fillObject
    };
  }
  return date;
};

export const fillRangeFromProps = (key, fillObject) => withProps(
  props => ({
    dates: props.dates.map(fillRange(get(props, key), fillObject))
  })
);

export const fillAvailableDates = (fillObject = { ...CELL.WHITE, disabled: false }) => withProps(
  props => ({
    dates: props.dates.map(date => {
      if (props.availableDates.some(availableDate => moment(availableDate).isSame(moment(date.date, 'day')))) {
        return {
          ...date,
          ...fillObject,
        };
      }
      return date;
    })
  })
);

export const fillFrontBack = withProps(
  ({ dates }) => {
    const frontFill = moment(dates[0].date).startOf('month').day();
    const backFill = 6 - moment(dates[0].date).endOf('month').day();
    const mapDefault = () => ({ color: colors.white, textColor: colors.disabledLight, disabled: true });
    return {
      dates: [
        ...range(0, frontFill).map(mapDefault),
        ...dates,
        ...range(0, backFill).map(mapDefault)
      ]
    };
  }
);

export const fillDefault = (fillObject = defaultOptions.fillDefault) => withProps(
  ({ dates = [], monthYear = moment().format('MMMM YYYY') }) => {
    const firstDayOfMonth = moment(monthYear, 'MMMM YYYY').startOf('month');
    const newDates = range(0, firstDayOfMonth.daysInMonth()).map(offset => {
      const currentDate = moment(firstDayOfMonth).add(offset, 'day');
      const existingDate = dates.find(date => moment(date.date).isSame(currentDate, 'day'));
      if (existingDate) {
        return existingDate;
      }
      return {
        ...fillObject,
        date: currentDate,
        day: offset + 1
      };
    });
    return { dates: newDates };
  }
);

export const mergingAvailability = (dates, availabilities) => {
  return dates.map(date => {
    const tripAvailabilityThatDay = availabilities.find(a => moment(a.date).isSame(date.date));
    if (tripAvailabilityThatDay) {
      let status = tripAvailabilityThatDay && tripAvailabilityThatDay.status;
      const replaceableDay = tripAvailabilityThatDay.is_replaceable;
      if (replaceableDay && status === 'n/a') status = 'replaceable';
      return {
        ...date,
        status,
        disabled: status === 'n/a'
      };
    }
    return date;
  });
};


export const highlightSelected = (options = defaultOptions.highlightSelected) => withProps(
  ({ dates, value }) => {
    const newDates = dates.map(date => {
      let dateOptions = options;
      if (date.status === 'replaceable') {
        dateOptions = defaultOptions.highlightSelectedReplaceable;
      }
      if (date.date && value.some(selectedDate => moment(selectedDate.date).isSame(moment(date.date), 'day') && date.status !== 'n/a')) {
        return {
          ...date,
          ...dateOptions
        };
      }
      return date;
    });
    return { dates: newDates };
  }
);

export const handleSingle = withHandlers({
  onPress: props => date => {
    props.onChange([date]);
  }
});

export const handleMultiple = withHandlers({
  onPress: ({ onChange, value }) => date => {
    const idx = findIndex(value, selectedDate => moment(selectedDate).isSame(moment(date.date), 'day'));
    if (idx < 0) {
      onChange([...value, moment(date.date).toDate()]);
    } else {
      onChange(value.filter((_, arrIdx) => idx !== arrIdx));
    }
  }
});

export const handleAvailability = compose(
  renameProp('value', 'dates'),
  withHandlers({
    onPress: ({ onChange, dates, fillMode }) => date => {
      const idx = findIndex(dates, selectedDate => moment(selectedDate.date).isSame(moment(date.date), 'day'));
      if (idx < 0 && fillMode !== 'ask') {
        onChange([...dates, {
          ...date,
          status: fillMode,
        }]);
      } else if (fillMode !== 'ask') {
        onChange([
          ...dates.slice(0, idx),
          { ...dates[idx], status: fillMode },
          ...dates.slice(idx + 1)
        ]);
      } else {
        onChange(dates.filter((_, arrIdx) => idx !== arrIdx));
      }
    }
  })
);

export const handleMonthYear = compose(
  withHandlers({
    onNext: ({ monthYear, onChangeMonthYear, onChangeMonthYearCallback }) => () => {
      const nextMonth = moment(monthYear, 'MMMM YYYY').add(1, 'month').format('MMMM YYYY');
      onChangeMonthYear(nextMonth);
      if (onChangeMonthYearCallback) onChangeMonthYearCallback(nextMonth);
    },
    onPrev: ({ monthYear, onChangeMonthYear, onChangeMonthYearCallback }) => () => {
      const prevMonth = moment(monthYear, 'MMMM YYYY').add(-1, 'month').format('MMMM YYYY');
      onChangeMonthYear(prevMonth);
      if (onChangeMonthYearCallback) onChangeMonthYearCallback(prevMonth);
    },
  }),
  withProps(
    ({ monthYear }) => ({
      label: monthYear
    })
  )
);
