import styled, { css } from 'styled-components';

const baseHelvathaicaFont = css`
  font-family: helvathaica, Tahoma, sans-serif;
`;

const baseHelvathaicaBoldFont = css`
  font-family: helvathaicaBold, Tahoma, sans-serif;
`;

const HeaderOne = styled.h1`
  ${baseHelvathaicaBoldFont}
  font-size: 25px;
  color: ${props => props.theme.darkBlue};
  font-weight: 500;
`;

const HeaderTwo = styled.h2`
  ${baseHelvathaicaBoldFont}
  font-size: 20px;
  color: ${props => props.theme.darkBlue};
  font-weight: 500;
`;

const HeaderThree = styled.h3`
  ${baseHelvathaicaBoldFont}
  font-size: 18px;
  color: ${props => props.theme.darkBlue};
  font-weight: 500;
`;

const HeaderFour = styled.h4`
  ${baseHelvathaicaBoldFont}
  font-size: 16px;
  color: ${props => props.theme.darkBlue};
  font-weight: 500;
`;

const Paragraph = styled.p`
  ${baseHelvathaicaFont}
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme.darkBlue};
  line-height: 1.57;
`;

export default {
  HeaderOne,
  HeaderTwo,
  HeaderFour,
  HeaderThree,
  Paragraph
};
