/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import config from 'config';
import { s3PathCreator } from 'utils/media';

import Icon from 'commons/components/Icon';
import Slider from 'commons/components/Slider';
import { Paragraph } from '../components/base/Typo';
import _DropDown from '../components/base/DropDown';
import TripCard from '../components/TripCard';

const ALL_CITY = 'all';

const TripCardLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener nofollow'
})`
  text-decoration: none;
`;

const Title = styled(Paragraph)`
  color: ${props => props.theme.orange};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;

  @media(max-width: 600px) {
    margin-bottom: 15px;
  }
`;

const DropDown = styled(_DropDown)`
  margin-bottom: 28px;

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.blueGrayLighter};
  padding: 16px 15px 14px 15px;
  border-left: 3px solid ${props => props.theme.orange};
  animation-name: appear;
  animation-duration: 0.2s;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
`;

const FooterLinkContainer = styled.div`
  display: flex;
`;

const FooterLink = styled(Paragraph).attrs({
  as: 'a',
  target: '_blank',
  rel: 'noopener nofollow'
})`
  text-decoration: none;
  display: flex;
  align-items: center;
  opacity: 0.6;
  font-size: 10px;
  font-weight: 500;
  color: #7a8e98;
`;

const TakeMeTourIcon = styled.img.attrs({
  src: `${s3PathCreator(config.uiKitAssetPath)('images')('/branding/logo-horizontal.png')}`,
  alt: 'TakeMeTour',
})`
  height: 15px;
`;

const RedirectButton = styled(Paragraph).attrs({
  as: 'a',
  target: '_blank',
  rel: 'noopener nofollow'
})`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  color: ${props => props.theme.blue};
`;

class TripCardList extends PureComponent {
  constructor(props) {
    super(props);
    this.URLSearchParams = new URLSearchParams(props.location.search);

    const bloggerName = this.URLSearchParams.get('name');
    const affId = this.URLSearchParams.get('affid');
    if (!bloggerName) {
      throw new Error('Please insert "name" in query string');
    }

    this.state = {
      isDataFetched: false,
      title: '',
      city: '',
      choices: [],
      bloggerName,
      affId,
      trips: [],
      areDropdownAndViewMoreHidden: false,
      redirectLink: '',
      exchangeRates: 0,
    };

    this.maxWidth = this.URLSearchParams.get('width') ? parseInt(this.URLSearchParams.get('width'), 10) : 569;
    this.onChangeCity = this.onChangeCity.bind(this);
  }

  async componentDidMount() {
    const { bloggerName } = this.state;
    const {
      trip_ids: trips,
      embed_hide_dropdown: areDropdownAndViewMoreHidden,
      embed_custom_title: title,
      link
    } = await fetch(`${config.apiPath}/trips/widget?partner=blogger-${bloggerName}`).then(res => res.json());
    const { exchangeRates } = await fetch(`${config.apiPath}/configs?keys=exchangeRates`).then(res => res.json());
    const choices = [...new Set(trips.map(trip => trip.destination_location))];

    this.setState({
      isDataFetched: true,
      title: title || 'Recommended Things to Do',
      choices,
      city: areDropdownAndViewMoreHidden ? ALL_CITY : (choices[0] || ''),
      trips,
      areDropdownAndViewMoreHidden,
      redirectLink: link ? link.value : null,
      exchangeRates: exchangeRates.rates || {},
    });
  }

  onChangeCity(city) {
    this.setState({ city });
  }

  render() {
    const {
      bloggerName,
      isDataFetched,
      title,
      trips,
      city,
      areDropdownAndViewMoreHidden,
      redirectLink,
      choices,
      exchangeRates,
      affId
    } = this.state;
    const filteredTrips = city === ALL_CITY ? trips : trips.filter(trip => {
      const isExactDestination = trip.destination_location === city;
      const isNearby = trip.trip_keywords && trip.trip_keywords.find(keyword => keyword === `city/Near ${trip.destination_location}`) !== undefined;
      return isExactDestination || isNearby;
    });
    const queryString = `?${affId !== 'noaffid' ? `affid=${affId || bloggerName}&` : ''}utm_campaign=${bloggerName}&utm_content=&utm_medium=affiliate&utm_source=${bloggerName}`;

    if (isDataFetched) {
      return (
        <Container>
          <Title>{title}</Title>
          {!areDropdownAndViewMoreHidden && <DropDown choices={choices} onChange={this.onChangeCity} />}
          {filteredTrips.length > 0 && (
            <Slider
              breakpoint={this.maxWidth}
              arrowSize={43}
              gap={20}
            >
              {filteredTrips.map((trip, index) => (
                <TripCardLink key={trip._id} href={`${config.webPath.en}/trip/${trip.slug}${queryString}`}>
                  <TripCard trip={trip} isBestSeller={index <= 2} exchangeRates={exchangeRates}/>
                </TripCardLink>
              ))}
            </Slider>
          )}
          <Footer>
            <FooterLinkContainer>
              <FooterLink href={`${config.webPath.en}${queryString}`}>
                Powered by&nbsp;&nbsp;<TakeMeTourIcon />
              </FooterLink>
              &nbsp;&nbsp;&nbsp;
              <FooterLink href={`${config.webPath.en}/affiliate`}>
                Become our partner
              </FooterLink>
            </FooterLinkContainer>
            {(!areDropdownAndViewMoreHidden && redirectLink) && (
              <RedirectButton href={`${redirectLink}${queryString}`}>
                View More
                <Icon name="chevron-right" size="20px" />
              </RedirectButton>
            )}
          </Footer>
        </Container>
      );
    }
    return <Container />;
  }
}

export default TripCardList;
