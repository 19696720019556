/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const Paragraph = styled.p`
  font-family: Montserrat, Tahoma, sans-serif;
  color: ${props => props.theme.black};
  font-size: 14px;
  font-weight: 400;
`;

export {
  Paragraph,
};
