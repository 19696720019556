import React from 'react';
import styled from 'styled-components';

import _Icon from 'commons/components/Icon';
import { Paragraph } from './Typo';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 291px;
  background-color: ${props => props.theme.white};
  border: solid 1px ${props => props.theme.blue};
  border-radius: 4px;
  color: ${props => props.theme.blue};

  > select {
    color: ${props => props.theme.blue};
  }

  > select:hover {
    color: ${props => props.theme.orange};
  }

  &:hover {
    color: ${props => props.theme.orange};
    border-color: ${props => props.theme.orange};
  }
`;

const Select = styled(Paragraph).attrs({
  as: 'select'
})`
  z-index: 1;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  padding: 9px 13px 8px 13px;
  background-color: transparent;
  border-width: 0;
`;

const Option = styled(Paragraph).attrs({
  as: 'option'
})`
  color: ${props => props.theme.orange};
`;

const Icon = styled(_Icon)`
  position: absolute;
  font-weight: bold;
  right: 8px;
`;

const Dropdown = props => {
  const { onChange = () => {}, className = '', choices = [] } = props;
  return (
    <Container className={className}>
      <Select onChange={e => onChange(e.target.value)}>
        {choices.map(choice => (
          <Option key={choice} value={choice}>{choice}</Option>
        ))}
      </Select>
      <Icon name="chevron-down" size="20px" />
    </Container>
  );
};

export default Dropdown;
