import React from 'react';
import styled from 'styled-components';
import chunk from 'lodash/chunk';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import Typo from '../Typo';

// import Loader from '../Loader';

const Container = styled.div`
  width: fit-content;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  background: ${props => props.theme.white}
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.16);
  padding: 8px;
`;

const ArrowContainer = styled.div`
  width: 48px;
  height: 32px;
  cursor: pointer;
  border-radius: 6px;
  padding-top: 10px;
  text-align: center;
  ${props => (props.rotate ? 'padding-right: 5px;' : 'padding-left: 5px;')}
  box-sizing: border-box;
`;

// const MonthYearArrow = styled.div`
//   width: 50%;
//   margin: 0 auto;
//   width: ${props => props.size * 6}px;
//   height: ${props => props.size * 6}px;
//   border-width: 2px;
//   border-style: solid;
//   border-color: transparent ${props => props.theme.darkBlue} ${props => props.theme.darkBlue} transparent;
//   ${props => (props.rotate ? 'transform: rotate(315deg);' : 'transform: rotate(135deg);')}
// `;

const MonthYearArrow = styled.i`
  font-size: 14px;
  margin: 0 auto;
  color: ${props => props.theme.darkBlue};
`;

const Arrow = props => {
  const { onClick, rotate } = props;
  return (
    <ArrowContainer rotate={rotate} onClick={onClick}>
      <MonthYearArrow className={`fas fa-angle-double-${rotate ? 'right' : 'left'}`} />
    </ArrowContainer>
  );
};

Arrow.propTypes = {
  onClick: PropTypes.func,
  rotate: PropTypes.bool
};

const MonthYearContainer = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  user-select: none;
  p {
    text-align: center;
  }
`;

const MonthYearLabel = styled.span`
  font-size: 22px;
  font-family: helvathaica, Tahoma, sans-serif;
  color: ${props => props.theme.darkBlue};
  font-weight: 500;
  padding: 8px 5px !important;
`;

const DayCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: ${props => props.height || 36}px;
  background-size: 8px;
  background-color: ${props => props.color};
  border-radius: 6px;
  border-bottom: none;
  border-right: none;
  user-select: none;
  ${props => !props.disabled && 'cursor: pointer;'}
  ${props => !props.disabled && `
    &:hover {
      background-color: ${props.replaceable ? lighten(0.1, props.theme.yellow) : lighten(0.1, props.theme.darkBlue)};
      p {
        color: ${props.replaceable ? props.theme.darkBlue : props.theme.white};
      }
      i {
        color: ${props.theme.white};
      } 
    }
  `}
  p {
    color: ${props => props.textColor};
    text-align: center;
    opacity: 0.7;
    font-weight: 500;
  }
`;

const DaysOfWeek = styled(DayCell)`
  p {
    font-size: 18px;
    font-family: helvathaica, Tahoma, sans-serif;
    font-weight: 300;
    color: ${props => props.theme.darkBlue};
  }
`;

const Weeks = styled.div`
  display: flex;
  flex-direction: row;
`;

const DayContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled(Typo.Paragraph)`
`;

// const LoadingContainer = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: ${props => rgba(props.theme.white, 0.55)};
//   ${props => !props.visible && 'display: none;'}
// `;

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const CalendarRenderer = (props) => {
  const {
    className,
    dates,
    label,
    onPress,
    onNext,
    onPrev,
    // loading,
  } = props;
  const weeks = chunk(dates, 7);
  return (
    <Container className={className}>
      <MonthYearContainer>
        <Arrow onClick={onPrev} />
        <MonthYearLabel>{label}</MonthYearLabel>
        <Arrow rotate onClick={onNext} />
      </MonthYearContainer>
      <Weeks>
        {daysOfWeek.map(day => (
          <DaysOfWeek key={day} disabled height={35}><Typo.Paragraph>{day}</Typo.Paragraph></DaysOfWeek>
        ))}
      </Weeks>
      {weeks.map((daysInWeek, weekIndex) => (
        <Weeks key={weekIndex}>
          {daysInWeek.map((day, dayIndex) => (
            <DayCell
              disabled={day.disabled}
              color={day.color}
              textColor={day.textColor}
              key={dayIndex}
              onClick={day.disabled ? null : () => onPress(day)}
              replaceable={day.status === 'replaceable'}
              showLightning={day.status === 'available'}
            >
              <DayContainer>
                <Number>{day.day || ''}</Number>
              </DayContainer>
            </DayCell>
          ))}
        </Weeks>
      ))}
      {/* <LoadingContainer visible={loading}>
        <Loader />
      </LoadingContainer> */}
    </Container>
  );
};

CalendarRenderer.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(undefined)]),
    textColor: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(undefined)]),
    date: PropTypes.object,
    day: PropTypes.number
  })),
  label: PropTypes.string,
  onPress: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  className: PropTypes.string,
  // loading: PropTypes.bool,
  // value: PropTypes.array
};

export default CalendarRenderer;
