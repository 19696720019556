/* eslint-disable no-param-reassign */
import React, { memo, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

import useMedia from '../../hooks/useMedia';

const SLIDER_LEFT_ONLY = 'SLIDER_LEFT_ONLY';
const SLIDER_RIGHT_ONLY = 'SLIDER_RIGHT_ONLY';
const SLIDER_BOTH = 'SLIDER_BOTH';
const SLIDER_NOT_BOTH = 'SLIDER_NOT_BOTH';
const DIRECTION_LEFT = 'DIRECTION_LEFT';
const DIRECTION_RIGHT = 'DIRECTION_RIGHT';

const Container = styled.div`
  display: flex;
  align-items: center;
  ${props => (props.width ? `
    width: ${props.width}px;
    min-width: ${props.width}px;
  ` : 'width: 100%;')}

  @media (max-width: ${props => props.breakpoint}px) {
    width: 100%;
    min-width: unset;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  overflow-x: ${props => (props.canScroll ? 'auto' : 'hidden')};
`;

const ItemPack = styled.div`
  display: flex;
  transition: transform 0.5s ease 0s;
  > * {
    margin-right: ${props => props.gap}px;
  }
  >:nth-last-child(1) {
    margin-right: 0;
  }
`;

const Arrow = styled(Icon)`
  color: #09386e;
  cursor: pointer;
`;

const getSizes = ref => {
  if (ref.current) {
    return {
      displayWidth: ref.current.parentNode.offsetWidth,
      itemWidth: ref.current.firstChild.offsetWidth,
    };
  }
  return {
    displayWidth: 0,
    itemWidth: 0,
  };
};
const getTotalItemInOnePaginationPage = (displayWidth, itemWidth) => ((displayWidth !== 0 && itemWidth !== 0) ? Math.floor(displayWidth / itemWidth) : 0);
const getCurrentPaginationPage = (ref, distanceToSlide) => {
  if (ref.current) {
    const diff = ref.current.parentNode.getBoundingClientRect().left - ref.current.getBoundingClientRect().left;
    return Math.ceil((diff / distanceToSlide) + 1);
  }
  return 0;
};
const getDistanceToSlide = (itemWidth, totalItemInOnePaginationPage) => (itemWidth * totalItemInOnePaginationPage);
const getCurrentDistance = (currentPaginationPage, distanceToSlide) => ((currentPaginationPage - 1) * distanceToSlide);
const getTotalPaginationPage = (totalItem, totalItemInOnePaginationPage) => ((totalItem !== 0 && totalItemInOnePaginationPage !== 0) ? Math.ceil(totalItem / totalItemInOnePaginationPage) : 0);
const checkPaginationPageIsOnlyOneOrNot = (ref, gap) => {
  if (!ref.current) return true;
  const { displayWidth, itemWidth } = getSizes(ref);
  const totalItemInOnePaginationPage = getTotalItemInOnePaginationPage(displayWidth + gap + 1, itemWidth + gap);
  const totalPaginationPage = getTotalPaginationPage(ref.current.childElementCount, totalItemInOnePaginationPage);
  return totalPaginationPage <= 1;
};
const slideTo = (direction, ref, currentDistance, distanceToSlide) => {
  if (direction === DIRECTION_LEFT) {
    ref.current.style.transform = `translateX(-${currentDistance - distanceToSlide}px)`;
  } else if (direction === DIRECTION_RIGHT) {
    ref.current.style.transform = `translateX(-${currentDistance + distanceToSlide}px)`;
  }
};
const resetScrollLeft = element => { element.scrollLeft = 0; };
const resetTranslate = element => { element.style.transform = 'translateX(0)'; };
const onClickRightArrow = (ref, gap, setSliderState) => {
  const { displayWidth, itemWidth } = getSizes(ref);
  const totalItemInOnePaginationPage = getTotalItemInOnePaginationPage(displayWidth + gap, itemWidth + gap);
  const totalPaginationPage = getTotalPaginationPage(ref.current.childElementCount, totalItemInOnePaginationPage);
  const distanceToSlide = getDistanceToSlide(itemWidth + gap, totalItemInOnePaginationPage);
  const currentPaginationPage = getCurrentPaginationPage(ref, distanceToSlide);
  const currentDistance = getCurrentDistance(currentPaginationPage, distanceToSlide);
  const paginationPageAfterClick = currentPaginationPage + 1;

  if (paginationPageAfterClick < totalPaginationPage) {
    setSliderState(SLIDER_BOTH);
  } else {
    setSliderState(SLIDER_LEFT_ONLY);
  }

  if (currentPaginationPage < totalPaginationPage) {
    slideTo(DIRECTION_RIGHT, ref, currentDistance, distanceToSlide);
  }
};
const onClickLeftArrow = (ref, gap, setSliderState) => {
  const { displayWidth, itemWidth } = getSizes(ref);
  const totalItemInOnePaginationPage = getTotalItemInOnePaginationPage(displayWidth + gap, itemWidth + gap);
  const distanceToSlide = getDistanceToSlide(itemWidth + gap, totalItemInOnePaginationPage);
  const currentPaginationPage = getCurrentPaginationPage(ref, distanceToSlide);
  const currentDistance = getCurrentDistance(currentPaginationPage, distanceToSlide);
  const paginationPageAfterClick = currentPaginationPage - 1;

  if (paginationPageAfterClick > 1) {
    setSliderState(SLIDER_BOTH);
  } else {
    setSliderState(SLIDER_RIGHT_ONLY);
  }

  if (currentPaginationPage > 1) {
    slideTo(DIRECTION_LEFT, ref, currentDistance, distanceToSlide);
  }
};

const Slider = memo(props => {
  const {
    className = '',
    children,
    breakpoint = 600,
    width,
    arrowSize = 43,
    gap = 10,
  } = props;
  const [sliderState, setSliderState] = useState(SLIDER_RIGHT_ONLY);
  const ref = useRef(null);
  const isBreakpointMatched = useMedia([`(max-width: ${breakpoint}px)`, `(min-width: ${breakpoint + 1}px)`], [true, false], false);

  // on number of children is changed
  useEffect(() => {
    if (checkPaginationPageIsOnlyOneOrNot(ref, gap)) {
      setSliderState(SLIDER_NOT_BOTH);
    } else if (!isBreakpointMatched) {
      setSliderState(SLIDER_RIGHT_ONLY);
    }
    resetTranslate(ref.current);
    resetScrollLeft(ref.current.parentNode);
  }, [children.length]);

  // on window is resized to breakpoint
  useEffect(() => {
    if (isBreakpointMatched) {
      resetTranslate(ref.current);
      setSliderState(SLIDER_NOT_BOTH);
    } else if (!checkPaginationPageIsOnlyOneOrNot(ref, gap)) {
      setSliderState(SLIDER_RIGHT_ONLY);
    }
    resetScrollLeft(ref.current.parentNode);
  }, [isBreakpointMatched]);
  return (
    <Container className={className} width={width} breakpoint={breakpoint}>
      {(sliderState !== SLIDER_NOT_BOTH && sliderState !== SLIDER_RIGHT_ONLY) && (
        <Arrow name="chevron-left" size={`${arrowSize}px`} onClick={() => onClickLeftArrow(ref, gap, setSliderState)} />
      )}
      <ItemContainer canScroll={isBreakpointMatched}>
        <ItemPack ref={ref} gap={gap}>
          {children}
        </ItemPack>
      </ItemContainer>
      {(sliderState !== SLIDER_NOT_BOTH && sliderState !== SLIDER_LEFT_ONLY) && (
        <Arrow name="chevron-right" size={`${arrowSize}px`} onClick={() => onClickRightArrow(ref, gap, setSliderState)} />
      )}
    </Container>
  );
});

const withCheckWindow = C => (window ? C : null);

export default withCheckWindow(Slider);
