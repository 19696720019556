const defaultConfig = {
  apiPath: process.env.APIPATH || 'https://staging.takemetour.com/api',
  mediaPath: 'https://d17572qoeejomw.cloudfront.net',
  webPath: {
    en: 'https://staging.takemetour.com',
    th: 'https://pachantiew.staging.takemetour.com',
    zh: 'https://zh.staging.takemetour.com',
  },
  // assetPath: 'https://s3-ap-southeast-1.amazonaws.com/takemetour-3-0-dev',
  uiKitAssetPath: 'https://takemetour-uikit-asset-dev.storage.googleapis.com',
  gaToken: 'UA-48143288-5',
};

const stagingConfig = {
  apiPath: process.env.APIPATH || 'https://staging.takemetour.com/api',
  mediaPath: 'https://dja3e86j343dh.cloudfront.net',
  webPath: {
    en: 'https://staging.takemetour.com',
    th: 'https://pachantiew.staging.takemetour.com',
    zh: 'https://zh.staging.takemetour.com',
  },
  uiKitAssetPath: 'https://takemetour-uikit-asset-dev.storage.googleapis.com',
};

const productionConfig = {
  apiPath: process.env.APIPATH || 'https://www.takemetour.com/api',
  mediaPath: 'https://dglw4xbnd0ycq.cloudfront.net',
  webPath: {
    en: 'https://www.takemetour.com',
    th: 'https://pachantiew.takemetour.com',
    zh: 'https://zh.takemetour.com',
  },
  // assetPath: 'https://s3-ap-southeast-1.amazonaws.com/takemetour-3-0',
  uiKitAssetPath: 'https://ddhiorx9qbcay.cloudfront.net',
  gaToken: 'UA-48143288-4',
};

const environment = process.env.REACT_APP_ENV || 'development';
const environmentConfig = environment === 'production' ? productionConfig : stagingConfig;

module.exports = Object.assign(defaultConfig, environmentConfig);
